@import '../../../../../ui/variables/spacing.scss';
@import 'stylesheet';

.linkTitle {
    font-weight: 700;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    
  }

  .tabContainer{
    display: flex;
    align-items: center;
    margin: getSpacing(2) getSpacing(2);
    color: $--light---link--link-primary;
  }
  
  .iconAndTabContainer{
    display: flex;
    align-items: center;
    gap: getSpacing(1.5);
    
  }

  .linkActive{
    color: '#FFFF33' !important;

    &:hover {
        color:$--light---link--link-primary;
      }
    }  
  
  .linkInactive{

    &:hover {
        color:$--light---text--text-primary;
      }
  }

