@import '../../../../../../ui/variables/spacing.scss';
@import 'stylesheet';

.infoTitleContainer {
    font-weight: bold;
    display: flex;
    gap: getSpacing(0.5)
}

.checkbox {
    display: flex;
    flex-direction: column;
}

.inputContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: getSpacing(1);
}

.dateContainer {
    display: flex;
    gap: getSpacing(4);
}

.dialogContainer {
    display: flex;
    flex-direction: column;
    padding: getSpacing(4.5) getSpacing(4.5);
}

.titleAndButtonsPopin {
    display: flex;
    justify-content: space-between;
}

.buttonContainerTags {
    display: flex;
}

.buttonTags {
    padding-left: getSpacing(2);
}

.mandatoryTextContainer {
    display: flex;
    flex-direction: row;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: $--heading--heading-07;
    color: $--light---text--text-secondary;
    gap: getSpacing(0.5);
    margin-bottom: getSpacing(2);
}