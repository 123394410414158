@import '../../../../../../ui/variables/spacing.scss';
@import 'stylesheet';


.title {
  display: flex;
  font-weight: bold;
  text-align: left;
  font-size: $--heading--heading-07;
  justify-content: center;
}

.titleAndIcon{
  display: flex;
  gap:getSpacing(1) ;
}

.iconContainer {
  display: flex;
  align-self: center;
  margin: getSpacing(1) 0px 0px 0px;
}

.titleAndValueContainer {
  display: flex;
  flex-direction: column;
  gap:getSpacing(1);
  margin-bottom: getSpacing(1);
}

.valueAndUnit {
  display: flex;
  gap: getSpacing(1);
  align-items: baseline;
  justify-content: center;
}

.value {
  font-weight: bold;
  font-size: $--heading--heading-02;
}

.unit {
  font-weight: normal;
  font-size: $--heading--heading-07;
  color: $--light---text--text-secondary;
}

.material_icons_green {
  font-family: 'Material Icons Outlined', sans-serif;
  color: $--light---accent--accent-success;
  padding: getSpacing(0.5);
  border-radius: 8px;
  font-weight: normal;
  font-style: normal;
  font-size: $--display--display-01;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
}

.material_icons {
  font-family: 'Material Icons Outlined', sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: $--heading--heading-04;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
}
