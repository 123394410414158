@import '../../../../../ui/variables/spacing.scss';
@import 'stylesheet';

.gainPageContainer{
    display: flex;
    flex-direction: column;
    margin: getSpacing(2) getSpacing(6) ;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-style: normal;
}

.menuOn{
    padding-left: getSpacing(30);
}

.headerContainer{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    align-items: center;
    margin-bottom: getSpacing(4);
    gap: getSpacing(2);
}

.gainElement{
    display: flex;
    flex-direction: column;
}
.gain{
    display: flex;
    flex-direction: column;
    align-items: center;
}


.gainSubElement{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: getSpacing(2);
    gap: getSpacing(1);
}   

.unitAndValue{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: getSpacing(1);
}