@import '../../../../../ui/variables/spacing.scss';
@import 'stylesheet';

.exclusionPageContainer{
    display: flex;
    flex-direction: column;
    margin: getSpacing(2) getSpacing(6) ;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-style: normal;
}

.menuOn{
    padding-left: getSpacing(30);
}

.actionContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.exclusion{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: getSpacing(2);
}

.textContainer{
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: getSpacing(1);
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-size: $--body--body-03;
    font-style: normal;
    line-height: 18px;
}

.exclusionButton{
    display: flex;
}

.subTitle{
    font-weight: 700;
}

.contentContainer{
    display: flex;
    flex-direction: column;
    gap: getSpacing(4);
}

.exclusion_title{
    display: flex;
    justify-content: space-between;
}

.exclusion_title h1{
    font-size: $--heading--heading-02;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-weight: bold;
    line-height: 28px;
}

.parent_gap_container{
    display: flex;
    flex-direction: column;
    gap:getSpacing(3);
    padding-left: getSpacing(2);
    width: 100%;
}
