@import '../../../../../../../ui/variables/spacing.scss';
@import 'stylesheet';

.pageContainer{
  display: flex;
  flex-direction: column;
  gap: getSpacing(2);
  margin:getSpacing(2) getSpacing(6);
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  font-style: normal;
}

.menuOn{
  padding-left: getSpacing(30);
}

.kpisContainer{
  display: flex;
  justify-content: space-between; /* Les éléments se répartissent horizontalement avec un espacement égal */
  gap: getSpacing(2);
  flex-wrap: wrap;
}

.imbalanceAndStatus{
  display: flex;
  flex-direction: column;
  gap:getSpacing(2);
  width: 100%;
}

.imbalances{
  flex: 75%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.status{
  width: inherit;
}

.parent_gap_container{
  display: flex;
  flex-direction: column;
  gap:getSpacing(2);
  padding-left: getSpacing(2);
  width: 100%;
}

.manufacturer_information_title{
  display: flex;
  justify-content: space-between;
}

.manufacturer_information_title h1{
  font-size: $--heading--heading-02;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  font-weight: bold;
  line-height: 28px;
}
