@import '../../../../../ui/variables/spacing.scss';
@import 'stylesheet';


.siteName{
    border-radius: 8px;
    border: 1px solid #C3C5C5;
    width: 200px;
    height: 40px;
    padding: getSpacing(1);
}

.dialogContainer{
    display: flex;
    flex-direction: column;
    padding: getSpacing(4.5) getSpacing(4.5);
}
