@import '../../../../../../../ui/variables/spacing.scss';
@import 'stylesheet';

.header{
    background-color: $--light---background--layer-01-active;
    text-align: left;
    font-weight: bold;
}

.infoContainer{
    display: flex;
    flex-direction: column;
    font-size: $--body--body-03;  
    padding:getSpacing(2) getSpacing(0);
}

.unit{
    color: $--light---text--text-placeholder;
}

.gainCounterTitlePrimary{
    display: flex;
    font-size: $--heading--heading-07;
    font-weight: bold
}

.gainCounterTitleSecondary{
    display: flex;
    font-size: $--heading--heading-07;
}

.gainCounterValuePrimary{
    display: flex;
    font-size: $--heading--heading-01;
    font-weight: bold
}

.gainCounterValueSecondary{
    display: flex;
    font-size: $--heading--heading-06;
    font-weight: bold
}

.gainValueAndUnit{
    display: flex;
    gap: getSpacing(1);
    align-items: baseline;
}

.gainInformation{
    display: flex;
    flex-direction: row;
    gap: getSpacing(1);
    align-items: baseline;
}
.gainInformationPrimary{
    display: flex;
    flex-direction: column;
    gap: getSpacing(1);
}

.gainSecondary{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: getSpacing(1);
}

.yearAndGains{
    display: flex;
    flex-direction: row;
}

.dropDown{
    display: flex;
    padding-top:getSpacing(3)
}
