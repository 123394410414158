@import 'stylesheet';
@import '../../../../../ui/variables/spacing.scss';

.uploadFileText {
    display: flex;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-style: normal;
    flex-direction: column;
    gap: getSpacing(2);
    font-weight: normal;
}

.uploadContainer {
    display: flex;
    font-weight: bold;
    font-size: $--heading--heading-03;
}

.inputVariableContainer {
    display: flex;
    flex-direction: row;
    font-weight: normal;
    font-size: $--heading--heading-06;
    justify-content: left;
}

.importCurvePopinNameContainer {
    display: flex;
    font-weight: 500;
    font-size: $--heading--heading-04;
}

.importCurvesContainer {
    display: flex;
    flex-direction: row;
    border: solid 1px;
    border-color: $--light---border--border-strong-02 ;
    border-radius: 16px;
    flex-wrap: wrap;
}

.importCurvesContainer>*:not(:last-child) {
    border-right: 1px solid #C3C5C5;
}

.importBlockContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: getSpacing(2) getSpacing(4);
    gap: getSpacing(2);
    align-items: flex-start;
}

.errorContainer {
    display: flex;
    background-color: $--light---background--background-warning;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-style: italic;
    font-weight: 500;
    font-size: $--heading--heading-06;
    border-radius: getSpacing(0.5);
    padding: getSpacing(1);
    margin: getSpacing(2) getSpacing(4) 0 0;
}

.logoAndErrorTextContainer {
    display: flex;
    flex-direction: row;
    gap: getSpacing(2)
}

.inputVariable{
    text-decoration: underline;  
}

.downloadTemplateButton {
    color: $--light---link--link-primary;
    background-color: transparent;
    border:transparent;
    text-decoration: underline $--light---link--link-primary;
    vertical-align: middle;
    font-weight: bold;
    font-size: $--heading--heading-06;
}

.download_button_style {
    font-size:$--heading--heading-02;
    background-color: transparent;
    border: transparent;
    vertical-align: middle;
    font-weight: bold;
    margin-bottom: getSpacing(0.5);
    color: $--dark---icon--icon-primary;    
  }

  .titleAndExportButton{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .uploadCurveContainer{
    width: 100%;
  }
