@import '../../../../../../ui/variables/spacing.scss';
@import 'stylesheet';

.iconAndTabContainer{
    display: flex;
    align-items: center;
    gap: getSpacing(1.5);
}

.tabContainer{
    color:$--light---link--link-primary
}

.muiAccordionDetailsroot {
    padding: 0px !important
}

.muiListRoot{
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.muiAccordionSummaryroot{
    min-height: getSpacing(8) !important;
}
