@import '../../../../../../../ui/variables/spacing.scss';
@import 'stylesheet';

.download_button_style {
  font-size:$--heading--heading-02;
  background-color: transparent;
  border: transparent;
  vertical-align: middle;
  font-weight: bold;
  margin-bottom: getSpacing(0.5);
  color: $--dark---icon--icon-primary;
  
}

.status_button{
  display: flex;
  gap: getSpacing(2);
}

.icon_and_status_container{
  display: flex;
  gap:getSpacing(1);
  font-size: $--heading--heading-07;
  align-items: center;  
  color:$--light---button--button-primary-text-icon;
  padding: getSpacing(0.5) getSpacing(1);
  border-radius: 4px;
}

.iconApproved{
  background-color: $--light---accent--accent-success;
  width: 100px;
}

.iconRejected{
  background-color: $--light---accent--accent-alert;
  width: 100px;
}

.iconPending{
    background-color: $--light---accent--accent-warning;
    width: 150px;
    color:black;
  }

.button_check_style{
  font-size:$--heading--heading-06;
}

.material_icons_outlined {
  font-family: 'Material Icons Outlined', sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 12px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
}

.statusFilterContainer{
  display: flex;
  flex-direction: column;
  padding:0 getSpacing(2) ;  

}
.statusFilter{
  border-bottom: 1px solid $--light---border--border-subtle-01;
  width: 111%;
}


