@import '../../../../../../../../../ui/variables/spacing.scss';
@import 'stylesheet';

.forecastContainer{
    display: flex;
    flex-direction: column;
    border: 1px solid $--light---border--border-subtle-01 ;
    border-radius:  getSpacing(2);
    padding: getSpacing(2) getSpacing(3);
    justify-content: space-between;
    width:100%;
    gap: getSpacing(2);
}

.forecastImbalance{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.detailsContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    gap: getSpacing(2);
}

.detailsColumnContainer{
    width: 50%;
    display: flex;
    flex-direction: column;
}

.titleAndSubtitle{
    display: flex;
    flex-direction: column;}

.title{
    display: flex;
    font-weight: bold;
    font-size: $--heading--heading-05;
}

.logoAndSubtitle{
    display: flex;
    flex-direction: row;
    gap: getSpacing(1);
    align-items: center;
}

.subTitle{
    display: flex;
    font-weight: normal;
    font-size: $--heading--heading-08;
}

.valueAndUnit{
    display: flex;
    gap: getSpacing(0.5);
    align-items: baseline;
}

.value{
    font-weight: bold;
}

.value:hover::after {
    content: attr(data-full-value);
    position: absolute;
    background-color: #555;
    color: #fff;
    transform: translateX(-50%);
    white-space: nowrap;
    opacity: 0;
    transition: opacity 0.3s;
}

.value:hover::after {
    opacity: 1;
}

.unit{
    font-size: $--heading--heading-06;
    margin-top: 2px;
    font-weight: normal !important;
}

.material_icons {
    font-family: 'Material Icons Outlined', sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: $--heading--heading-04;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
  }
