@import '../../../../../ui/variables/spacing.scss';
@import 'stylesheet';

.referenceValueAndUnit{
    display: flex;
    flex-direction: column;
}

.parameterWithReferenceValueContainer{
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: $--heading--heading-07;
    margin-bottom: getSpacing(1);
  }
