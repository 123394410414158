@import '../../../../../ui/variables/spacing.scss';
@import 'stylesheet';

.boxContainer{
  display: flex;
  justify-content: flex-start;
  background-color:$--light---background--layer-01-active;
  width:fit-content;
  border-radius: 8px;
}

.smallBoxContainer{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  cursor: pointer;
  margin: getSpacing(1) ;
  width:fit-content;
  padding: getSpacing(0.5) getSpacing(2);
  border-radius: 4px;  
  font-weight: bold;
}
