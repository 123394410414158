@import '../../../../../ui/variables/spacing.scss';
@import 'stylesheet';

.dialogContainer{
    display: flex;
    flex-direction: column;
    padding: getSpacing(4.5) getSpacing(4.5);
}

.titleAndButtonsPopin{
    display: flex;
    justify-content: space-between;
}

.buttonContainerTags{
    display: flex;
}

.buttonTags{
    padding-left: getSpacing(2);
}

