@import '../../../../../../ui/variables/spacing.scss';
@import 'stylesheet';

.primaryValueContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: getSpacing(2);
}

.primaryValueAndUnit{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: getSpacing(1);
    min-height: 60px;
}
