@import '../../../../../ui/variables/spacing.scss';
@import 'stylesheet';

.inputContainer{
    display: flex;
    border: 1px solid $--light---border--border-strong-02;
    border-radius: 8px;
    width: 110px;
    height: 40px;
}
