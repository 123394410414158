@import '../../../../../../ui/variables/spacing.scss';
@import 'stylesheet';


.titleText{
    font-size : getSpacing(2.5);
    margin-top: getSpacing(1);
    margin-left: getSpacing(4);
}

.statusList{
    display: flex;
    gap: getSpacing(3);
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    font-weight: bolder;
}


