@import '../../../../../ui/variables/spacing.scss';
@import 'stylesheet';

.uploadFileAndMessageContainer{
    display: flex;
    justify-content: space-between;
    margin-right: getSpacing(5);
    align-items: flex-end;
}

.messageContainer{
    display: flex;
    color: $--light---text--text-placeholder;
    font-size: $--heading--heading-07;
}

.uploadAndDeleteFileContainer{ 
    display: flex; 
    cursor: pointer;
    gap:getSpacing(2);
}

