@import '../../../../../ui/variables/spacing.scss';
@import 'stylesheet';

.newSite{
    color: $--light---button--button-secondary-text-icon;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: getSpacing(1);
    font-weight: bold;
}

.menuOn{
    padding-left: getSpacing(35);
  }

  .headerContainer{
    padding-right: 0px !important;
  }

.newSiteDropdown{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.dropdownItem:hover, .dropdownItem:focus {
    color: var(--bs-dropdown-link-hover-color);
    text-decoration: none;
    background-color: var(--bs-dropdown-link-hover-bg);
}

.dropdownItem {
    display: block;
    clear: both;
    font-weight: 400;
    color: $--light---button--button-secondary-text-icon;
    text-align: inherit;
    background-color: rgba(0,0,0,0);
    border: 0;
}

.langAndFlag{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: inherit;
}

.nameAndAdmin{
    color: $adminButton;
    display: flex;
    gap: getSpacing(2);
    font-weight: bold;
}

.logo{
    padding-left: getSpacing(4);
    padding-right: getSpacing(4);
    background-color: white;
    border-bottom-left-radius: getSpacing(4);
}
