@import '../../../../../../../ui/variables/spacing.scss';
@import 'stylesheet';

.download_button_style {
  font-size:$--heading--heading-02;
  background-color: transparent;
  border: transparent;
  vertical-align: middle;
  font-weight: bold;
  margin-bottom: getSpacing(0.5);
  margin-left:  getSpacing(0.5);
  color: $--dark---icon--icon-primary;
}

.upload_button_style {
  font-size: $--heading--heading-02;
  background-color: transparent;
  border: transparent;
  vertical-align: middle;
  font-weight: bold;
  margin-bottom: getSpacing(0.5);
  margin-left: getSpacing(0.5);
  color: $--dark---icon--icon-primary;
}
