@import '../../../../../ui/variables/spacing.scss';
@import 'stylesheet';

.titlePopinContainer{
    display: flex;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: $--heading--heading-01;   
}

.dialogContainer{
    display: flex;
    flex-direction: column;
    padding: getSpacing(4.5) getSpacing(4.5);
}

.titleAndButtons{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.buttonsContainer{
    display: flex;
    gap : getSpacing(2)
}

.iconAndTextPopinContainer{
    display: flex;   
    gap:getSpacing(2);
    align-items: center;    
}

.iconParameterContainer{
    color: $--light---button--button-primary-background;
    margin-bottom:getSpacing(1);
}


.mandatoryTextContainer{
    display: flex;
    flex-direction: row;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: $--heading--heading-07;
    color : $--light---text--text-secondary;
    gap: getSpacing(0.5);
    margin-bottom: getSpacing(2);
}

.referenceBoxesContainer{
    display: flex;
    flex-direction: row;
    gap: getSpacing(6);
    margin-bottom: getSpacing(2);
}

.referenceValueTextContainer{
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: $--heading--heading-06;
    margin-bottom: getSpacing(2);
}

.andOrContainer{
    display: flex;
    margin-top: getSpacing(4);
}
