@import '../../../../../ui/variables/spacing.scss';
@import 'stylesheet';

.failurePageContainer{
    display: flex;
    flex-direction: column;
    margin: getSpacing(2) getSpacing(6) ;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-style: normal;
}

.menuOn{
    padding-left: getSpacing(30);
}

.metroscope_container{
    display: flex;
    gap: getSpacing(1);
    align-items: center;
    color: $--light---button--button-primary-background;
}

.link_metroscope{
    font-weight: bold;
    text-decoration-line: underline;
}

.impact_container{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: getSpacing(12);
}

.impact_value_container{
    display: flex;
    justify-content: center;
    gap: getSpacing(1);
    align-items: center;
}

.impact_value{
    font-weight: normal;
}

.report_container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.tip{
    display: flex;
    flex-direction: column;
    gap: getSpacing(1);
}

.actionContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.title{
    display: flex;
    justify-content: space-between;
}

.title h1{
    font-size: $--heading--heading-02;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-weight: bold;
    line-height: 28px;
}

.parent_gap_container{
    display: flex;
    flex-direction: column;
    gap:getSpacing(3);
    padding-left: getSpacing(2);
    width: 100%;
}

