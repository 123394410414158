@import '../../../../../../ui/variables/spacing.scss';
@import 'stylesheet';


.title{
    display: flex;
    padding: getSpacing(1) getSpacing(0);
    width: 100%;
}

.titleText{
     font-size :$--heading--heading-03;
     margin-left: getSpacing(4);
}

.kpis{
    gap: getSpacing(2);
    width: 70%;
    display: flex;
    flex-direction: column;
}

.imbalances{
    display: flex;
    gap: getSpacing(2);
    margin-bottom: getSpacing(2);
    width: 100%;
}

.imbalancesAndGraph{
    display:flex;
    flex-direction: column;
    width:100%;
    padding:0px getSpacing(4)
}

.accordionContainer{
    height: 930px;
}

.dropDownAndPlotContainer{
    display: flex;
    flex-direction: column;
    gap: getSpacing(2);
    width: 100%;
}

.titleKpi{
    font-weight: bold;
}
