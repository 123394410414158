@import '../../../../../ui/variables/spacing.scss';
@import 'stylesheet';

.manufacturerPageContainer{
    display: flex;
    flex-direction: column;
    margin: getSpacing(2) getSpacing(6);
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-style: normal;
}

.menuOn{
    padding-left: getSpacing(30);
}
   
.parameterCardAndGraphContainer{
    display: flex;
    flex-direction: column;
    border:solid 1px;
    border-color:$--light---border--border-strong-02 ;
    border-radius: 16px;
    overflow: hidden;
}

.subTitle{
    display: flex;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: $--heading--heading-03;
    gap:  getSpacing(2); 
    align-items: center;
    margin-bottom: 0px;
}

.manufacturer_information_title{
    display: flex;
    justify-content: space-between;
}

.manufacturer_information_title h1{
    font-size: $--heading--heading-02;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-weight: bold;
    line-height: 28px;
}

.parent_gap_container{
    display: flex;
    flex-direction: column;
    gap:getSpacing(3);
    padding-left: getSpacing(2);
    width: 100%;
}

.iconAndTextContainer{
    display: flex;   
    gap:getSpacing(2);
    align-items: center;    
}

.cardContainer{
    display: flex;
    padding: getSpacing(5) getSpacing(4);
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-style: normal;    
    width: 100%;
}

.curvesContainer{
    display: flex;
    flex-direction: row; 
    border-top: 1px solid $--light---border--border-strong-02;
    flex-wrap: wrap;
}

.curvesContainer > *:not(:last-child) {
    border-right: 1px solid #C3C5C5;
}

.textAndButtonContainer{
    display: flex;    
    justify-content: space-between;
    margin-bottom: getSpacing(2);    
}  

.buttonContainer{
    width: 180px;
    height: 40px;
}

.parameterAndReferenceInformationContainer{
    padding: getSpacing(2) getSpacing(4);    
}

.referenceValueContainer{
    display: flex;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: $--heading--heading-06;
}

.referenceContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-style: normal;    
}

.referenceNameContainer{
    font-weight: bold;
    font-size: $--heading--heading-07;
}

.referenceValue{
    display: flex;
    font-weight: bold;
    font-size: $--heading--heading-02;
}

.unit{
    font-weight: normal;
    font-size: $--body--body-01;
}

.valueAndUnit{
    display: flex;
    flex-direction: row;
    gap:getSpacing(0.5);
    align-items: flex-end;
}

.referencesContainer{
    display: flex;
    gap: getSpacing(4);
}

.referenceAndDateOfModification{
    display: flex;
    gap: getSpacing(3);
    margin-bottom:  getSpacing(2);
    align-items: center;
}

.modificationDateContainer{
    font-weight: normal;
    font-size: $--heading--heading-07;
    color: $--light---text--text-secondary;
}

.importCurveNameContainer{
    display: flex;
    font-weight: 500;
    padding: getSpacing(2) getSpacing(0) getSpacing(2) getSpacing(6);
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-style: normal; 
    font-size: $--heading--heading-04;
}


.curveAndTitle{
    flex-basis: 50%;
    flex-direction: column;
    display: flex;
}

.curveNameAndDate{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap:getSpacing(2);
}

.errorMessage{
    display: flex;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: $--heading--heading-06;
    color:$--light---text--text-secondary;
}
