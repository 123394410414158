@import 'stylesheet';
@import '../../../../../../ui/variables/spacing.scss';

.graphContainer{
    display: flex;
    flex-direction: column;
}

.arrowContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.graphAndDateContainer{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.datePickerAndTitle{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.datePickerAndTitleContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.datePickerContainer{
    display: flex;
    justify-content: flex-end;
    max-width: 170px;
   
}

.datePickerTitle{
    display: flex;
    justify-content: flex-end;
    font-size:$--heading--heading-07;
    font-weight: 700;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
}
