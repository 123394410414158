@import '../../../../../ui/variables/spacing.scss';
@import 'stylesheet';

.adminPageContainer{
    display: flex;
    flex-direction: column;
    margin: getSpacing(2) getSpacing(6) ;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-style: normal;
}

.menuOn{
    padding-left: getSpacing(30);
}

.information{
    display: flex;
    text-align: left;
}

.title_button{
    display: flex;
    justify-content: space-between;
}

.title_button h1{
    font-size: $--heading--heading-02;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-weight: bold;
    line-height: 28px;
}

.admin_container{
    padding: getSpacing(0) getSpacing(2);
}

.parent_gap_container{
    display: flex;
    flex-direction: column;
    gap:getSpacing(3);
    padding-left: getSpacing(2);
    width: 100%;
}
