@import '../../../../../../../ui/variables/spacing.scss';
@import 'stylesheet';

.pageContainer{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: getSpacing(2);
    margin:getSpacing(2) getSpacing(5);
    padding-right: getSpacing(3);
}

.menuOn{
  padding-left: getSpacing(30);
}

.status{
  flex: 15%;
  flex-grow: 1;
}
