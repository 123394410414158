@import 'stylesheet';
@import '../../../../../ui/variables/spacing.scss';

.icon_container{
    display: flex;
    justify-content: center;
}

.icon_container_margin{
    margin-right: getSpacing(4);
}
