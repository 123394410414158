@import '../../../../../../../../../ui/variables/spacing.scss';
@import 'stylesheet';

.detailsElement{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    margin-left: getSpacing(3);
}

.detailsTitle{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    font-weight: bold;
}

.valueAndUnit{
    display: flex;
    gap: getSpacing(0.5);
    align-items: baseline;
}

.value{
    font-weight: bold;
}

.value:hover::after {
    content: attr(data-full-value);
    position: absolute;
    background-color: #555;
    color: #fff;
    transform: translateX(-50%);
    white-space: nowrap;
    opacity: 0;
    transition: opacity 0.3s;
}

.value:hover::after {
    opacity: 1;
}

.unit{
    font-size: $--heading--heading-06;
    margin-top: 2px;
    font-weight: normal !important;
}
