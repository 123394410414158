@import '../../../../../ui/variables/spacing.scss';
@import 'stylesheet';
.unit{
    padding-left: getSpacing(1);
}

.advice{
    padding-bottom: getSpacing(4);
}

.buttonContainerIdentity{
  display: flex;
  width: auto;
  justify-content: flex-end;
  gap: getSpacing(1);
}

.dialogContainer{
  display: flex;
  flex-direction: column;
  padding: getSpacing(4.5) getSpacing(4.5);
}

.mandatoryTextContainer{
  display: flex;
  flex-direction: row;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: $--heading--heading-07;
  gap: getSpacing(0.5);
  margin-bottom: getSpacing(2);
}