.spinner {
    animation: spin infinite 5s linear;
    font-size: 30px;
}

.spinnerSmall {
    animation: spin infinite 5s linear;
    font-size: 15px;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
