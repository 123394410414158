@import '../../../../../../../src/ui/variables/spacing.scss';
@import 'stylesheet';


.dialogContainer{
    display: flex;
    flex-direction: column;
    padding: getSpacing(4.5) getSpacing(4.5);
}

.inputs{
    width: 100%;
}

.input_container{
    display: flex;
    gap: getSpacing(4);
    padding: getSpacing(1) 0 getSpacing(1) 0;
}

.title{
    font-size: 12px;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-weight: bold;
    line-height: 16px;
    width: 30%;
    display: flex;
    flex-direction: column;
}

.titleAndButtonsPopin{
    display: flex;
    justify-content: space-between;
}

.buttonContainerTags{
    display: flex;
}

.buttonTags{
    padding-left: getSpacing(2);
}

.mandatoryTextContainer{
    display: flex;
    flex-direction: row;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: $--heading--heading-07;
    color : $--light---text--text-secondary;
    gap: getSpacing(0.5);
    margin-bottom: getSpacing(1);
}
