@import '../../../../../../../../ui/variables/spacing.scss';
@import 'stylesheet';

.statusAndValue{
    display: flex;    
    align-items: center; 
    gap: getSpacing(2);
   
}

.iconAndText{
    display: flex;
    gap:getSpacing(2);
    align-items: center;
}

.statusName{
    display: flex;
    font-weight: bold;
    font-size: $--heading--heading-07;
}

.statusValue{
    display: flex;
    font-weight: bold;
    font-size: $--heading--heading-05;
}

.iconBorder{
    padding:getSpacing(1)
}

.material_icons{    
    font-family: 'Material Icons Outlined', sans-serif;
    color: $--light---button--button-primary-background;
    padding:getSpacing(1);
    border-radius: getSpacing(1);
    font-weight: normal;
    font-style: normal;
    font-size: getSpacing(3);
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
}
