//Background
$--light---background--background: #F7F9FA;
$--light---background--background-header: #FFFFFF;
$--light---background--layer-01: #FFFFFF;
$--light---background--layer-01-hover: #EBEEEF;
$--light---background--layer-01-active: #EBEEEF;
$--light---background--layer-02: #F7F9FA;
$--light---background--layer-02-hover: #EBEEEF;
$--light---background--layer-02-active: #EBEEEF;
$--light---background--background-success: #BCF453;
$--light---background--background-alert: #FF9789;
$--light---background--background-warning: #FFE74F;
$--light---background--background-info: #80CDFF;


//Button
$--light---button--button-primary-background: #ED0000;
$--light---button--button-primary-hover: #D10000;
$--light---button--button-primary-text-icon: #FFFFFF;
$--light---button--button-secondary-background: #FFFFFF;
$--light---button--button-secondary-hover: #F7F9FA;
$--light---button--button-secondary-text-icon: #ED0000;
$--light---button--button-tertiary-background: #FFFFFF;
$--light---button--button-tertiary-hover: #F7F9FA;
$--light---button--button-tertiary-text-icon: #ED0000;
$--light---button--button-disabled: #EBEEEF;

//Text
$--light---text--text-primary: #222B2D;
$--light---text--text-secondary: #6E7781;
$--light---text--text-placeholder: #909498;
$--light---text--text-on-color: #FFFFFF;
$--light---text--text-helper: #969BA1;
$--light---text--text-tertiary: #ED0000;
$--light---text--text-error: #D10000;
$--light---text--text-disabled: #85888A;
$--light---text--text-on-color-disabled: #85888A;
$--badge-tertiary: #374649;
$adminButton:#1976d2;

//Field
$--light---field--field: #FFFFFF;
$--light---field--field-hover: #F7F9FA;
$--light---field--field-disabled: #EBEEEF;

//Link
$--light---link--link-primary: #ED0000;
$--light---link--link-secondary: #373649;
$--light---link--link-disabled: #85888A;

//Icon
$--light---icon--icon-primary: #222B2D;
$--light---icon--icon-on-color: #FFFFFF;
$--light---icon--icon-interactive: #ED0000;
$--light---icon--icon-disabled: #85888A;
$--light---icon--icon-on-color-disabled: #85888A;

//Border
$--light---border--border-subtle-01: #D0D3D4;
$--light---border--border-strong-01: #8C9294;
$--light---border--border-subtle-02: #F7F9FA;
$--light---border--border-strong-02: #C3C5C5;
$--light---border--border-interactive: #ED0000;
$--light---border--border-disabled: #85888A;

//Accent
$--light---accent--accent-success: #40A900;
$--light---accent--accent-alert: #D10000;
$--light---accent--accent-warning: #FFE74F;
$--light---accent--accent-info: #285AFF;

//Data
$--light---data--data-red-200: #ED9A9A;
$--light---data--data-pink-200: #F48FB1;
$--light---data--data-purple-200: #CE93D8;
$--light---data--data-deep-purple-200: #B39DDB;
$--light---data--data-indigo-200: #9FA8DA;
$--light---data--data-blue-200: #90CAF9;
$--light---data--data-cyan-200: #80DEEA;
$--light---data--data-teal-200: #80CBC4;
$--light---data--data-grass-200: #C5E1A5;
$--light---data--data-lime-200: #E6EE9C;
$--light---data--data-yellow-200: #FFF59D;
$--light---data--data-orange-200: #FFCC80;
$--light---data--data-deep-orange-200: #FFAB91;
$--light---data--data-red-500: #ED0000;
$--light---data--data-pink-500: #E91E63;
$--light---data--data-purple-500: #9C27B0;
$--light---data--data-deep-purple-500: #673AB7;
$--light---data--data-indigo-500: #3F51B5;
$--light---data--data-blue-500: #2196F3;
$--light---data--data-cyan-500: #009EB3;
$--light---data--data-teal-500: #80CBC4;
$--light---data--data-grass-500: #6FA136;
$--light---data--data-lime-500: #87931A;
$--light---data--data-yellow-500: #D7C100;
$--light---data--data-orange-500: #D68100;
$--light---data--data-deep-orange-500: #FF5722;
$--light---data--data-red-800: #A30000;
$--light---data--data-pink-800: #AD1457;
$--light---data--data-purple-800: #6A1B9A;
$--light---data--data-deep-purple-800: #4527A0;
$--light---data--data-indigo-800: #283593;
$--light---data--data-blue-800: #1565C0;
$--light---data--data-cyan-800: #00838F;
$--light---data--data-teal-800: #00695C;
$--light---data--data-grass-800: #547A29;
$--light---data--data-lime-800: #5C6411;
$--light---data--data-yellow-800: #9B8B00;
$--light---data--data-orange-800: #AA6600;
$--light---data--data-deep-orange-800: #DD3400;

//Miscellaneous
$--light---miscellaneous--overlay: #333333;

$--dark---background--background: #12121C;
$--dark---background--background-header: #23232C;
$--dark---background--layer-01: #2C2C35;
$--dark---background--layer-01-active: #32323A;
$--dark---background--layer-02: #383840;
$--dark---background--layer-02-active: #3B3B43;
$--dark---background--background-success: #BCF453;
$--dark---background--background-alert: #FF9789;
$--dark---background--background-warning: #FFE74F;
$--dark---background--background-info: #80CDFF;
$--dark---border--border-subtle-01: #616161;
$--dark---border--border-subtle-02: #616161;
$--dark---border--border-strong-01: #FFFFFF;
$--dark---border--border-strong-02: #FFFFFF;
$--dark---border--border-interactive: #FF153C;
$--dark---border--border-disabled: #525252;
$--dark---button--button-primary: #FF153C;
$--dark---button--button-primary-hover: #FF617B;
$--dark---button--button-secondary: #FFFFFF;
$--dark---button--button-secondary-hover: #D9D9D9;
$--dark---button--button-tertiary: #FF153C;
$--dark---button--button-tertiary-hover: #FF617B;
$--dark---button--button-disabled: #525252;
$--dark---data--data-red-200: #ED9A9A;
$--dark---data--data-pink-200: #F48FB1;
$--dark---data--data-purple-200: #CE93D8;
$--dark---data--data-deep-purple-200: #B39DDB;
$--dark---data--data-indigo-200: #9FA8DA;
$--dark---data--data-blue-200: #90CAF9;
$--dark---data--data-cyan-200: #80DEEA;
$--dark---data--data-teal-200: #80CBC4;
$--dark---data--data-grass-200: #C5E1A5;
$--dark---data--data-lime-200: #E6EE9C;
$--dark---data--data-yellow-200: #FFF59D;
$--dark---data--data-orange-200: #FFCC80;
$--dark---data--data-deep-orange-200: #FFAB91;
$--dark---data--data-red-500: #ED0000;
$--dark---data--data-pink-500: #E91E63;
$--dark---data--data-purple-500: #9C27B0;
$--dark---data--data-deep-purple-500: #673AB7;
$--dark---data--data-indigo-500: #3F51B5;
$--dark---data--data-blue-500: #2196F3;
$--dark---data--data-cyan-500: #009EB3;
$--dark---data--data-teal-500: #80CBC4;
$--dark---data--data-grass-500: #6FA136;
$--dark---data--data-lime-500: #87931A;
$--dark---data--data-yellow-500: #D7C100;
$--dark---data--data-orange-500: #D68100;
$--dark---data--data-deep-orange-500: #FF5722;
$--dark---data--data-red-800: #A30000;
$--dark---data--data-pink-800: #AD1457;
$--dark---data--data-purple-800: #6A1B9A;
$--dark---data--data-deep-purple-800: #4527A0;
$--dark---data--data-indigo-800: #283593;
$--dark---data--data-blue-800: #1565C0;
$--dark---data--data-cyan-800: #00838F;
$--dark---data--data-teal-800: #00695C;
$--dark---data--data-grass-800: #547A29;
$--dark---data--data-lime-800: #5C6411;
$--dark---data--data-yellow-800: #9B8B00;
$--dark---data--data-orange-800: #AA6600;
$--dark---data--data-deep-orange-800: #DD3400;
$--dark---field--field-01: #12121C;
$--dark---field--field-hover: #12121C;
$--dark---field--field-disabled: #525252;
$--dark---icon--icon-primary: #FF153C;
$--dark---icon--icon-on-color: #FFFFFF;
$--dark---icon--icon-disabled: #707070;
$--dark---icon--icon-on-color-disabled: #707070;
$--dark---link--link-primary: #FF153C;
$--dark---link--link-secondary: #FFFFFF;
$--dark---link--link-disabled: #525252;
$--dark---miscellaneous--overlay: #333333;
$--dark---text--text-primary: #FFFFFF;
$--dark---text--text-secondary: #FF153C;
$--dark---text--text-placeholder: #919198;
$--dark---text--text-on-color: #FFFFFF;
$--dark---text--text-helper: #919198;
$--dark---text--text-error: #E36666;
$--dark---text--text-disabled: #707070;
$--dark---text--text-on-color-disabled: #707070;

/* Text-size styles */

$--display--display-01: 40px;

$--display--display-02: 32px;

$--heading--heading-01: 26px;

$--heading--heading-02: 22px;

$--heading--heading-03: 20px;

$--heading--heading-04: 18px;

$--heading--heading-05: 16px;

$--heading--heading-06: 14px;

$--heading--heading-07: 12px;

$--heading--heading-08: 10px;

$--body--body-01: 18px;

$--body--body-02: 16px;

$--body--body-03: 14px;

$--link--link-1: 16px;

$--link--link-1-hover: 16px;

$--link--link-2: 14px;

$--link--link-2-hover: 14px;

$--utility--button: 14px;

$--utility--label: 12px;

$--utility--helper-text: 12px;

$--utility--caption: 10px;


