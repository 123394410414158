@import '../../../../../ui/variables/spacing.scss';
@import 'stylesheet';

.title{
    display: flex;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: $--heading--heading-07;
    line-height: 100%;
}

.subTitle{
    display: flex;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: $--heading--heading-03;
    gap:  getSpacing(2); 
    align-items: center;
    margin-bottom: 0px;
}

.manufacturerSubTitle {
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-size: $--heading--heading-06;
    margin-bottom: 0px;
}

.value{
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    text-align: left;
    font-weight: normal;
    font-size: $--heading--heading-06;
}

.identityContainer{
    padding: getSpacing(2) getSpacing(4);
}

.parameterCardAndGraphContainer{
    display: flex;
    flex-direction: column;
    border:solid 1px;
    border-color:$--light---border--border-strong-02 ;
    border-radius: 16px;
}

.textAndButtonContainer{
    display: flex;    
    justify-content: space-between;
    margin-bottom: getSpacing(2);    
}

.iconAndTextContainer{
    display: flex;   
    gap:getSpacing(2);
    align-items: center;    
}

.buttonContainer{
    width: 180px;
    height: 40px;
}
