@import '../../../../../ui/variables/spacing.scss';
@import 'stylesheet';

.mlModelPageContainer{
    display: flex;
    flex-direction: column;
    margin: getSpacing(2) getSpacing(6) ;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-style: normal;
}

.mlPageContainer{
    display: flex;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-style: normal;
    padding: getSpacing(2) getSpacing(4);
}

.mlPagetitle{
    display: flex;
    justify-content: space-between;
}

.mlPagetitle h1{
    font-size: $--heading--heading-02;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-weight: bold;
    line-height: 28px;
}

.menuOn{
    padding-left: getSpacing(30);
}

.parent_gap_container{
    display: flex;
    flex-direction: column;
    gap:getSpacing(3);
    padding-left: getSpacing(2);
    width: 100%;
}
