@import '../../../../../../../ui/variables/spacing.scss';
@import 'stylesheet';

.pageContainer{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: getSpacing(2);
    margin:getSpacing(2) getSpacing(5);
    padding-right: getSpacing(3);
}

.menuOn{
  padding-left: getSpacing(30);
}

.gainPlaceHolder{
    background-color: $--light---background--layer-01-active;
    text-align: center;
    font-weight: bold;
}

.imbalanceAndStatus{
  display: flex;
  flex-direction: column;
  gap:getSpacing(2);
  width: 100%;
}

.parent_gap_container{
    display: flex;
    flex-direction: column;
    gap:getSpacing(2);
    padding-left: getSpacing(2);
    width: 100%;
  }

.imbalances{
  flex: 75%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.status{
  flex: 15%;
  flex-grow: 1;
}
