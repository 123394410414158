@import '../../../../../ui/variables/spacing.scss';
@import 'stylesheet';

.descriptionContainer{
    padding-bottom: getSpacing(2);
}

.infoContainer{
    display: flex;
    flex-wrap: wrap;
    gap: getSpacing(2) getSpacing(3);
}

.infoTitleContainer{
    font-weight: bold;
}

.titleContainer{
    display:flex;
}

.inputContainer{
    display: flex;
    align-items: center;
    width: auto;
    gap: 2px;
}

.inputFailuresContainer{
    border-radius: 8px;
    width: 150px;
    height: 40px;
}

.description{
    width: 100% !important;
    min-height: 200px;
}

.dialogContainer{
    display: flex;
    flex-direction: column;
    padding: getSpacing(4.5) getSpacing(4.5);
}

.titleAndButtonsPopin{
    display: flex;
    justify-content: space-between;
}

.buttonContainerTags{
    display: flex;
}

.buttonTags{
    padding-left: getSpacing(2);
}

.mandatoryTextContainer{
    display: flex;
    flex-direction: row;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: $--heading--heading-07;
    gap: getSpacing(0.5);
    margin-bottom: getSpacing(2);
}
