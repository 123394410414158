@import '../../../../../ui/variables/spacing.scss';
@import 'stylesheet';

.primaryValueContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.primaryValueAndUnit{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: getSpacing(1);
}

.primaryTitle{
    font-weight: bold;
}

.slotInfoContainer{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;

}

.secondaryValueContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.SecondaryValueAndUnit{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: getSpacing(1);
    width: 100%;
    justify-content: space-between;
}

.valueAndUnit{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: getSpacing(1);
}

.css-50zrbt-MuiTooltip-tooltip{
    background-color: red;
}

.nextSlot{
    display: flex;
    gap: getSpacing(1);
}